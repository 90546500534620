<template>
	<main class="main">
		<section class="container">
      <Crumbs :data="crumbs" />

			<TopBlock
				v-if="blocks"
				:data="{ ...blocks[0], ...aniamtionContent }"
				:options="[
					{ ...blocks[1], icon: iconLeft },
					{ ...blocks[2], icon: iconRight },
				]"
			></TopBlock>
		</section>

		<section class="main__gradient main__gradient--light" v-if="pages">
			<div class="container">
				<ul class="advantages">
					<li
						class="advantages__item advantages__item--no-padding"
						v-for="(item, index) in pages"
						:key="index"
						v-show="item.alias !== 'partner-program'"
					>
						<router-link class="advantages__link" :to="'/holder/capabilities/' + item.alias">
						<!--
						{{item.alias}}
						{ name: 'CapabilitiesPages', params: { alias: item.alias } }
						-->
							<div
								class="advantages__content"
								:class="{ 'advantages__content--small': item.image }"
							>
								<h4 class="advantages__title">{{ item.title }}</h4>
								<div
									class="advantages__text editor"
									v-html="item.description"
								></div>
							</div>
							<img
								:src="item.image"
								:alt="item.title"
								aria-hidden="true"
								class="advantages__image"
							/>
						</router-link>
					</li>
					<li
						class="advantages__item advantages__item--no-padding"
						v-if="blocks"
					>
						<div class="advantages__link">
							<div class="advantages__content advantages__content--small">
								<h4 class="advantages__title">{{ blocks[3].title }}</h4>
								<div
									class="advantages__text editor"
									v-html="blocks[3].content"
								></div>
							</div>
							<img
								:src="require('@/assets/images/icons/advantages_gears.svg')"
								alt="Совсем скоро"
								aria-hidden="true"
								class="advantages__image"
							/>
						</div>
					</li>
				</ul>
			</div>
		</section>

		<section class="container" v-if="blocks">
			<div class="main__box">
				<h2 class="main__title main__title--center">{{ blocks[4].title }}</h2>
				<div
					class="main__text main__text--center main__text--max-width editor"
					v-html="blocks[4].content"
				></div>
				<Advantages
					:data="[
						{
							...blocks[5],
							icon: require('@/assets/images/icons/icon_lock.svg'),
						},
						{
							...blocks[6],
							icon: require('@/assets/images/icons/icon_credit-card.svg'),
						},
						{
							...blocks[7],
							icon: require('@/assets/images/icons/icon_check.svg'),
						},
						{
							...blocks[8],
							icon: require('@/assets/images/icons/icon_award.svg'),
						},
					]"
				></Advantages>
			</div>
		</section>

		<section class="container">
			<div class="main__box">
				<Map
					:data="{
						icon: require('@/assets/images/icons/map_type-3.svg'),
						groupedMarkers: getGroupedPoints,
						title: 'Пункты приема заявлений и выдачи ЕСК',
					}"
					:filters="filters"
				></Map>
			</div>
		</section>
	</main>
</template>

<script>
import TopBlock from '@/components/TopBlock.vue'
import Advantages from '@/components/Advantages.vue'
import Map from '@/components/Map.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'Capabilities',

	components: {
    Crumbs,
		TopBlock,
		Advantages,
		Map,
	},

	data() {
		return {
			aniamtionContent: {
				animationBg: require('@/assets/images/animation/animation_circle-green.svg'),
				animationFront: require('@/assets/images/animation/animation_credit-card-new.png'),
				animationClassBg: 'pulse',
				animationClassFront: 'slide-left',
			},
			iconLeft: require('@/assets/images/icons/icon_credit-card.svg'),
			iconRight: require('@/assets/images/icons/icon_smartphone.svg'),
			crumbs: null,
		}
	},

	computed: {
		blocks() {
			return this.$store.getters.BLOCKS[this.$options.name]
		},

		pages() {
			let pages = this.$store.getters.PAGES
			if (pages) {
				pages = pages.filter(
					item =>
						item.template == 'capabilities' &&
						item.alias != 'card-bank' &&
						item.alias != 'card-social'
				)
			}
			return pages
		},

		getGroupedPoints() {
			return this.$store.getters.POINTS_ORGANIZATION_GROUPED
		},

		filters() {
			return {
				cardType: false,
				list: false,
				type: 'points',
				store: {
					dispatch: 'SET_POINT_LIST_APPLIED_FILTERS',
					getters: 'POINT_LIST_APPLIED_FILTERS',
				},
				filters: [
					{
						name: 'Фильтр',
						data: [
							{
								type: 'checkbox',
								cardType: true,
								title: 'Тип карты',
								list: [
									{
										id: 'isBankCardIssuer',
										name: 'Банковская',
									},
									{
										id: 'isNonFinancialCardIssuer',
										name: 'Нефинансовая',
									},
								],
							},
							{
								type: 'checkbox',
								title: 'Пункт выдачи ЕСК',
								list: this.$store.getters.POINT_LIST_FILTERS,
							},
						],
					},
				],
			}
		},
	},

	mounted() {
		let self = this
		self.$nextTick(() => {
			self.$store.dispatch('GET_POINTS_ORGANIZATION')
			let query = {
				page: self.$route.fullPath,
				block: self.$options.name,
			}
			self.$store.dispatch('GET_BLOCKS', query)
			self.$store.dispatch('GET_PAGES')
		})

    this.crumbs = [
      {
        link: 'HolderHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Возможности карты',
      },
    ]
	},
}
</script>
