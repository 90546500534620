<template>
	<ul class="advantages">
		<li class="advantages__item" v-for="(item, index) in data" :key="index">
			<img
				v-if="item.icon"
				:src="item.icon"
				:alt="item.title"
				aria-hidden="true"
				class="advantages__icon"
			/>
			<div
				class="advantages__content"
				:class="{ 'advantages__content--small': item.image }"
			>
				<h4 class="advantages__title">{{ item.title }}</h4>
				<div class="advantages__text editor" v-html="item.content"></div>
			</div>
			<img
				v-if="item.image"
				:src="item.image"
				:alt="item.title"
				aria-hidden="true"
				class="advantages__image"
			/>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Advantages',

	props: {
		data: {
			type: Array,
			required: true,
		},
	},
}
</script>
