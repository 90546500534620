var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main"},[_c('section',{staticClass:"container"},[_c('Crumbs',{attrs:{"data":_vm.crumbs}}),(_vm.blocks)?_c('TopBlock',{attrs:{"data":Object.assign({}, _vm.blocks[0], _vm.aniamtionContent),"options":[
					Object.assign({}, _vm.blocks[1], {icon: _vm.iconLeft}),
					Object.assign({}, _vm.blocks[2], {icon: _vm.iconRight}) ]}}):_vm._e()],1),(_vm.pages)?_c('section',{staticClass:"main__gradient main__gradient--light"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"advantages"},[_vm._l((_vm.pages),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(item.alias !== 'partner-program'),expression:"item.alias !== 'partner-program'"}],key:index,staticClass:"advantages__item advantages__item--no-padding"},[_c('router-link',{staticClass:"advantages__link",attrs:{"to":'/holder/capabilities/' + item.alias}},[_c('div',{staticClass:"advantages__content",class:{ 'advantages__content--small': item.image }},[_c('h4',{staticClass:"advantages__title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"advantages__text editor",domProps:{"innerHTML":_vm._s(item.description)}})]),_c('img',{staticClass:"advantages__image",attrs:{"src":item.image,"alt":item.title,"aria-hidden":"true"}})])],1)}),(_vm.blocks)?_c('li',{staticClass:"advantages__item advantages__item--no-padding"},[_c('div',{staticClass:"advantages__link"},[_c('div',{staticClass:"advantages__content advantages__content--small"},[_c('h4',{staticClass:"advantages__title"},[_vm._v(_vm._s(_vm.blocks[3].title))]),_c('div',{staticClass:"advantages__text editor",domProps:{"innerHTML":_vm._s(_vm.blocks[3].content)}})]),_c('img',{staticClass:"advantages__image",attrs:{"src":require('@/assets/images/icons/advantages_gears.svg'),"alt":"Совсем скоро","aria-hidden":"true"}})])]):_vm._e()],2)])]):_vm._e(),(_vm.blocks)?_c('section',{staticClass:"container"},[_c('div',{staticClass:"main__box"},[_c('h2',{staticClass:"main__title main__title--center"},[_vm._v(_vm._s(_vm.blocks[4].title))]),_c('div',{staticClass:"main__text main__text--center main__text--max-width editor",domProps:{"innerHTML":_vm._s(_vm.blocks[4].content)}}),_c('Advantages',{attrs:{"data":[
						Object.assign({}, _vm.blocks[5],
							{icon: require('@/assets/images/icons/icon_lock.svg')}),
						Object.assign({}, _vm.blocks[6],
							{icon: require('@/assets/images/icons/icon_credit-card.svg')}),
						Object.assign({}, _vm.blocks[7],
							{icon: require('@/assets/images/icons/icon_check.svg')}),
						Object.assign({}, _vm.blocks[8],
							{icon: require('@/assets/images/icons/icon_award.svg')}) ]}})],1)]):_vm._e(),_c('section',{staticClass:"container"},[_c('div',{staticClass:"main__box"},[_c('Map',{attrs:{"data":{
						icon: require('@/assets/images/icons/map_type-3.svg'),
						groupedMarkers: _vm.getGroupedPoints,
						title: 'Пункты приема заявлений и выдачи ЕСК',
					},"filters":_vm.filters}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }