<template>
	<div class="main__box main__box--animation" v-if="data">
		<h1 class="main__title">{{ data.title }}</h1>
		<div class="main__text-box">
			<div class="main__text editor" v-html="data.content"></div>
		</div>
		<p
			v-if="data.subtitle"
			class="main__sub-title"
			:class="data.hasOwnProperty('listClass') ? '' : 'mb-0'"
		>
		<!--:class="{ 'mb-0': data.content2 && !data.list }"-->
			{{ data.subtitle }}
		</p>
		<div
			class="main__text editor"
			:class="
				data.hasOwnProperty('listClass') ? data.listClass : 'main__text--small'
			"
			v-if="data.content2 && !data.list"
			v-html="data.content2"
		></div>

		<div
			class="editor editor--list-column-2"
			v-if="data.list"
			v-html="data.list"
		></div>

		<div class="options flex flex--between" v-if="options || advantages">
			<template v-if="options">
				<div
					class="options__box flex"
					v-for="(option, index) in options"
					:key="index"
				>
					<img
						:src="option.icon"
						:alt="option.title"
						aria-hidden="true"
						class="icon"
					/>

					<div class="options__content">
						<h4 class="options__title">{{ option.title }}</h4>
						<div class="options__text" v-html="option.content"></div>
					</div>
				</div>
			</template>

			<template v-if="advantages">
				<ul class="main__advantages-list">
					<li
						class="main__advantages-item"
						v-for="(item, index) in JSON.parse(
							advantages.content.replace('<p>', '').replace('</p>', '')
						)"
						:key="index"
					>
						<div class="main__advantages-box">
							<span class="main__advantages-number">{{ item.number }}</span>
							{{ item.title }}
						</div>
						<div class="main__advantages-text editor" v-html="item.text"></div>
					</li>
				</ul>

				<p class="main__text text-gray text-small">{{ advantages.content2 }}</p>
			</template>
		</div>
		<div class="main__animation-box" :class="data.animationClassBox">
			<div
				class="main__animation-bg"
				:class="data.animationClassBg"
				v-if="data.animationBg"
			>
				<img :src="data.animationBg" alt="Фон анимации" aria-hidden="true" />
			</div>

			<div
				class="main__animation-front"
				:class="data.animationClassFront"
				v-if="data.image || data.animationFront"
			>
				<img
					:src="data.animationFront || data.image"
					alt="Картинка с анимацией"
					aria-hidden="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TopBlock',

	props: {
		data: {
			type: Object,
			required: true,
		},
		advantages: {
			type: Object,
		},
		options: {
			type: Array,
		},
	},
}
</script>
