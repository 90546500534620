<template>
	<nav class="crumbs">
		<ul itemscope itemtype="https://schema.org/BreadcrumbList" class="crumbs__list">
			<li
				:class="whiteText ? 'crumbs__item--white' : null"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
				class="crumbs__item"
				v-for="(item, index) in data"
				:key="index"
			>
				<router-link
					:to="{ name: item.link, params: item.params }"
          itemprop="item"
					class="crumbs__link"
          :style="`color: ${whiteText ? '#fff' : null}`"
					v-if="item.link"
					><span itemprop="name">{{ item.text }}</span></router-link
				>
				<span
					:style="`color: ${whiteText ? '#fff' : null}`"
					class="crumbs__link"
					v-else
          itemprop="name"
					>{{ item.text }}</span
				>
        <meta itemprop="position" :content="index">
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'Crumbs',

	props: {
		data: {
			type: Array,
			required: true,
		},
    whiteText: {
		  type: Boolean,
      required: false,
			default: false,
		},
	},
}
</script>
